import { Link } from "gatsby";
import React from "react";
import Layout from "../../../other/layout";
import SEO from "../../../other/seo";

export default function Index() {
  return (
    <Layout>
      <SEO title="DFS Policies" />

      <div className="row dfs-policies-wrapper">
        <h1 className="text-center">DFS Policies</h1>
        <div class="cat-hg ">
          <div class="cat-u-tl">
            <div class="cat-desc-tl">
              <div class="cat-title">
                <Link to="/why-choose-dfs/dfs-policies/dealer-policies/">
                  Dealer Policies
                </Link>
              </div>
            </div>
          </div>

          <div class="cat-u-tl">
            <div class="cat-desc-tl">
              <div class="cat-title">
                <Link to="/why-choose-dfs/dfs-policies/privacy-policy/">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>

          <div class="cat-u-tl">
            <div class="cat-desc-tl">
              <div class="cat-title">
                <Link to="/why-choose-dfs/dfs-policies/100-quality-guarantee/">
                  100% Quality Guarantee
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
